import React from "react";
import "./contacts.scss";
import ConsultationModal from "../../Components/ConsultationModal";
import {useSelector} from "react-redux";
import useFetch from "../../Hooks/useFetch";

function Contacts() {
    const languageInformation = useSelector((state) => state.language.language);
    const {data, loading, error} = useFetch(
        process.env.REACT_APP_API_URL +
        `/abouts?locale=${languageInformation.language}&populate=*`,
    );
    const [modalOpen, setModalOpen] = React.useState(false);

    return loading ? (
        <div className="skeleton-app">
            <img
                src={process.env.PUBLIC_URL + "/img/preloader-application.svg"}
                alt="preloader"
            />
        </div>
    ) : (
        <div className="content">
            <div className="contacts">
                <h4 className="contacts__title cen">
                    {data[0]?.attributes?.contactTitle}
                </h4>

                <div className="contacts__net">
                    <div className="contacts__net-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3156.2999020049756!2d14.458638100000002!3d50.0918555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b95beb2e103b5%3A0xe943d5779a43d8f4!2sShadeko%20eu!5e1!3m2!1spl!2spl!4v1738677551302!5m2!1spl!2spl"
                            allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div className="contacts__net-text">
                        <section>
                            <b>{data[0]?.attributes?.contactAddressLabel}</b>
                            <span>{data[0]?.attributes?.contactAddressBody}</span>
                        </section>

                        <section>
                            <b>{data[0]?.attributes?.contactPhoneLabel}</b>
                            <span>{data[0]?.attributes?.contactPhoneBody}</span>
                        </section>

                        <section>
                            <b>{data[0]?.attributes?.contactEmailLabel}</b>
                            <span>{data[0]?.attributes?.contactEmailBody}</span>
                        </section>

                        <button
                            onClick={() => setModalOpen(!modalOpen)}
                            className="contacts__open-modal"
                        >
                            {data[0]?.attributes?.buttonText}
                        </button>
                    </div>
                </div>
            </div>

            <ConsultationModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                text={data}
            />
        </div>
    );
}

export default Contacts;
